import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { getChosenCellOptionsFromScheduleSelector } from '@store/forms/formStorageSelectors';
import API from '@utils/AdminApi';
import ShowsSelect from '@components/ShowsSelect';
import { useData } from '../DataProvider';

const mergeIssuedOptions = (options) => {
  const appendYear = (year, program, values) => {
    if (!(year in options)) {
      options[year] = {};
    }

    for (const month in values) {
      appendMonth(month, year, program, values[month])
    }

  }

  const appendMonth = (month, year, program, values) => {
    if (!(month in options[year])) {
      options[year][month] = {};

    }

    for (const week in values) {
      appendWeek(week, month, year, program, values[week]);
    }
  }

  const appendWeek = (week, month, year, program, values) => {
    if (!(week in options[year][month])) {
      options[year][month][week] = [];
    }

    let opt = [];

    for (const day in values) {
      const _month = `0${month}`.slice(-2);
      const _day = `0${day}`.slice(-2);

      values[day].forEach( val => {
        opt.push({ title: val.title, value: `${val.piss_id}`, event_start: val.event_start });
      });

    }

    options[year][month][week] = options[year][month][week].concat(opt);
  }

  return (program, values) => {
    for (const year in values) {
      appendYear(year, program, values[year]);
    }
  }
}

function Issued() {
  const request = useRef(null);

  const { filter, program, issued, setValue } = useData();

  const [ isLoading, setIsLoading ] = useState(false);

  /**
   * список опшинов из модуля Schedule, для мультиселекта
   * содержатся выбранные события (pissId) и их скомпонованные названия
   */
  const cellOptionsFromSchedule = useSelector(
    getChosenCellOptionsFromScheduleSelector,
  );

  const [options, setOptions] = useState();

  useEffect(() => {
    let cellEntitiesValues = [];

    try {
      for (const key in cellOptionsFromSchedule) {
        // TODO убрать спред объекта (причина - утилита getFullPath, добавляет/изменяет поле option'а)
        cellEntitiesValues.push({ ...cellOptionsFromSchedule[key] });
      }
    } catch (error) {
      cellEntitiesValues = [];
    }

    /**
     * для каждого селектора отдельно запрашивается список option'ов из АПИ
     * в случае если выбрана программа (по сути канал, e.g. Первый канал, Россия 24),
     * и фильтр выбран в статусе 'issued', запрашиваются опшины для мультиселекта
     */
    if (program && program.length && filter === 'issued') {
      const requestData = JSON.stringify(program);

      if (requestData === request.current) {
        return;
      }

      if (request.current !== null) {
        setValue('issued', null);
      }

      request.current = requestData;

      setIsLoading(true);

      const promises = [];

      /**
       * program.value - это скомпонованный в виде строки список id'шников:
       * программы, события, подтипа события, и pissId
       */

      program.forEach(({ value }) => promises.push(API.getProgramIssuedPicker(value)));

      Promise.all(promises).then(value => {
        const data = {};
        const merge = mergeIssuedOptions(data);

        program.forEach((entry, index) => {
          merge(entry.value, value[index]);
        });

        requestData === request.current && setOptions(data);

        setIsLoading(false);
      });
    } else if (filter === 'issued') {
      setOptions(null);
      setValue('issued', null);
    }
  }, [program, filter, cellOptionsFromSchedule]);

  const issuedValue = useMemo(() => issued ?? [], [issued]);

  if (isLoading) {
    return <div className="form-control">загрузка...</div>;
  }

  return (
    <div className="form-control">
      <ShowsSelect
        options={options}
        selected={issuedValue}
        placeholder="Выберите передачу"
        single={false}
        line={false}
        onChange={(value) => setValue('issued', value)}
      />
    </div>
  );
}

/*
      <MultiSelect
        search={true}
        options={options}
        selected={issuedValue}
        placeholder="Выберите передачу"
        single={false}
        line={false}
        onChange={(value) => setValue('issued', value)}
      />
*/

export default Issued;
