import React, { useEffect, useState, useMemo, useCallback } from 'react';

import moment from 'moment';
import 'moment/locale/ru';
import * as locales from '@components/DateRange/locale';
import { useSelector } from 'react-redux';
import { dateColorCallback } from '@utils/helpers';
import { FormControl, FormLabel, Text, useOutsideClick} from "@chakra-ui/react"
import { DateRange as DateRangeSelect } from '@components/DateRange';
import { ReactComponent as CalendarIcon } from '@components/UI/assets/calendar.svg';

function DateRange(props) {

  const dates = useSelector((state) => state.status.statusObject);

  const calendarWrapperRef = React.useRef();
  const [showCalendar, setShowCalendar] = useState(false);

  useOutsideClick({
    ref: calendarWrapperRef,
    handler: () => setShowCalendar(false)
  });

  const [focusedRange, setFocusedRange] = useState([0]);
  const [selectionRange, setSelectionRange] = useState({
    startDate: moment().toDate(),
    endDate:moment().toDate(),
    key: "selection"
  });

  const onChange = (dateRange) => {
    if (props.onChange) {
        props.onChange(dateRange);
    }
  }

  const onRangeChange = (ranges) => {
    if (ranges === null) {
      return
    }

    const { startDate, endDate } = ranges.selection;

    const range = {
      startDate,
      endDate,
      key: "selection"
    };

    setSelectionRange(range);

    if (focusedRange[1] === 1 || ranges.range) {
      setTimeout(() => setShowCalendar(false), 200);
      onChange(range);
    }
  };

  useEffect(() => {
    if (props.value) {
      setSelectionRange(props.value);
    }
  }, [ props.value ]);

  const minDate = useMemo(
    () => moment().set('year', props.start ?? 2018).set('month', 0).set('day', 1).toDate(),
    [ props.start ]
  );

  const maxDate = useMemo(
    () => props.maxDate ?? moment().toDate(),
    [ props.maxDate, props.end ]
  );

  const dateColor = useMemo(() => {
    return dateColorCallback(dates);
  }, [ dates, maxDate ]);

  const rangeColors = ['#F15B5B'];

  return (
    <div>
      <FormControl
        isRequired={props.isRequired ?? false}
        className={props.className ?? "form-control"}
      >
        { !!props.label && <FormLabel htmlFor="range">{ props.label }</FormLabel> }
        <div
          className='date-range-text'
          onClick={() => setShowCalendar(true)}
        >
          <Text ml={1} mr={1}>
            {moment(selectionRange.startDate).format('DD.MM.YY')}
          </Text>
          {`-`}
          <Text ml={1}>
            {moment(selectionRange.endDate).format('DD.MM.YY')}
          </Text>
          <div className='date-range-text-icon'>
            <CalendarIcon />
          </div>
        </div>

        <div
          className={`date-range--calendar-wrapper ${showCalendar ? 'show' : ''}`}
          ref={calendarWrapperRef}
        >
          <DateRangeSelect
            showDateDisplay={false}
            showSelectionPreview={false}
            editableDateInputs={false}
            onChange={onRangeChange}
            onRangeFocusChange={setFocusedRange}
            moveRangeOnFirstSelection={false}
            ranges={[selectionRange]}
            locale={locales['ru']}
            minDate={minDate}
            maxDate={maxDate}
            rangeColors={rangeColors}
            weekdayDisplayFormat='EEEEEE'
            dayContentRenderer={dateColor}
          />
        </div>
      </FormControl>
    </div>
  )
}

export default DateRange;
